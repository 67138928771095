<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')">
      <base-card :with-style="false" title="Tambah Metode Pembayaran">
        <create-payment-form
          cardless
          :with-summary="false"
          validate-button-text="Simpan"
          less-amount-disabled
          :min-amount="1"
          @save="onSave"
        />
      </base-card>
    </base-modal>

    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import CreatePaymentForm from './create-payment-form.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    BaseModal,
    CreatePaymentForm,
  },
  props: {
    visible: Boolean,
    orderId: String,
    paymentId: String,
  },
  emits: ['close', 'created'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSave(data) {
      this.loading = true;

      const [paymentMethod] = data;
      const [, err] = await this.request(`/api/v1/payment-methods`, {
        method: 'post',
        data: {
          data: {
            type: 'payment-methods',
            attributes: {
              payment_amount: 0,
              cashier_payment_amount:
                typeof paymentMethod.amount === 'string'
                  ? Number(paymentMethod.amount.replace(/\D/g, ''))
                  : 0,
              receipt_number: paymentMethod.receiptNumber,
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.orderId,
                },
              },
              payment: {
                data: {
                  type: 'payments',
                  id: this.paymentId,
                },
              },
              ...(paymentMethod.officeBankId
                ? {
                    'office-bank': {
                      data: {
                        type: 'office-banks',
                        id: paymentMethod.officeBankId,
                      },
                    },
                  }
                : {}),
              ...(paymentMethod.paymentMethodTypeId
                ? {
                    'payment-method-type': {
                      data: {
                        type: 'payment-method-types',
                        id: paymentMethod.paymentMethodTypeId,
                      },
                    },
                  }
                : {}),
            },
          },
        },
      });

      this.loading = false;

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.$emit('close');
        this.$emit('created');
      }
    },
  },
};
</script>
