<template>
  <component
    :is="cardless ? 'div' : 'base-card'"
    title="Daftar Faktur Pembayaran"
  >
    <datatable compact :columns="tableColumns">
      <template v-slot:tbody="{ classes }">
        <template v-for="payment in payments.data">
          <tr :key="payment.id" :class="[classes.tr]">
            <td :class="[classes.td]">
              <span class="font-bold text-gray-900">
                {{ payment.attributes.code }}
              </span>
            </td>
            <td :class="[classes.td]">
              {{ payment.attributes.destination_office_code }} /
              {{ payment.attributes.origin_warehouse_code }}
            </td>
            <td :class="[classes.td]">
              {{ payment.attributes.createdAt | formatDate }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                payment.attributes[
                  editable ? 'cashier_total_amount' : 'total_amount'
                ] | toCurrency
              }}
            </td>
            <td
              v-if="columns.includes('state')"
              :class="[classes.td, 'text-center']"
            >
              <svg
                v-if="payment.attributes.state === 'pending'"
                title="Metode pembayaran harus diverifikasi terlebih dahulu"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="mx-auto h-5 w-5 text-yellow-500"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                v-else-if="payment.attributes.is_verified"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="mx-auto h-5 text-green-500"
                title="Pembayaran telah diverifikasi"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <base-button v-else size="sm" @click="onVerifyPayment(payment)"
                >Verifikasi</base-button
              >
            </td>
            <td :class="[classes.td, 'text-right']">
              <button class="text-right" @click="onCollapse(payment)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mx-auto h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  v-if="collapsedId === payment.id"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  v-else
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <template v-if="collapsedId === payment.id">
            <tr
              v-for="paymentMethod in usedPaymentMethods"
              :key="`${payment.id}-${paymentMethod.id}`"
              :class="[
                classes.tr,
                paymentMethod.attributes.canceled ? 'bg-red-50' : 'bg-gray-50',
              ]"
            >
              <td :class="[classes.td]"></td>
              <td
                :class="[
                  classes.td,
                  paymentMethod.attributes.canceled ? 'text-red-600 line-through' : '',
                ]"
              >
                <div class="flex gap-2">
                  <p v-if="paymentMethod.attributes.balance_used === 'restock'">
                    Saldo Restock
                  </p>
                  <p v-else>
                    {{ paymentMethod.attributes.payment_method_type }}
                    <span v-if="paymentMethod.attributes.office_bank_name">
                      -
                      {{ paymentMethod.attributes.office_bank_name }}
                    </span>
                  </p>
                </div>
              </td>
              <td
                :class="[
                  classes.td,
                  paymentMethod.attributes.canceled ? 'text-red-600 line-through' : '',
                ]"
              >
                {{ paymentMethod.attributes.updatedAt | formatDate }}
              </td>
              <td
                :class="[
                  classes.td,
                  'text-right',
                  paymentMethod.attributes.canceled ? 'text-red-600 line-through' : '',
                ]"
              >
                <div class="flex justify-end">
                  <form
                    v-if="
                      editable &&
                      editPaymentMethod.visible &&
                      editPaymentMethod.id === paymentMethod.id
                    "
                    @submit.prevent="onSubmitEditPaymentMethod"
                  >
                    <base-input
                      ref="editPaymentMethodInput"
                      currency
                      type="text"
                      placeholder="Nominal pembayaran"
                      v-model="editPaymentMethod.value"
                    />
                  </form>
                  <p v-else>
                    {{
                      paymentMethod.attributes.cashier_payment_amount
                        | toCurrency
                    }}
                    <span
                      v-if="
                        editable &&
                        payment.attributes.is_first_payment &&
                        paymentMethod.attributes.created_by_type !==
                          'cashier' &&
                        paymentMethod.attributes.cashier_payment_amount !==
                          paymentMethod.attributes.payment_amount
                      "
                      class="tooltip text-red-600"
                    >
                      *
                      <span class="tooltiptext"
                        >Nominal awal
                        {{
                          paymentMethod.attributes.payment_amount | toCurrency
                        }}</span
                      >
                    </span>
                  </p>
                </div>
              </td>
              <td
                v-if="columns.includes('state')"
                :class="[classes.td, 'text-center']"
              >
                <svg
                  v-if="paymentMethod.attributes.is_verified"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="mx-auto h-5 w-5 text-green-500"
                  title="Metode pembayaran telah diverifikasi"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div v-else class="flex justify-center gap-1">
                  <base-button
                    v-if="paymentMethod.attributes.canceled"
                    size="sm"
                    color="white"
                    @click="onRestorePaymentMethod(paymentMethod.id)"
                    >Restore</base-button
                  >
                  <template v-else>
                    <template
                      v-if="
                        !editPaymentMethod.visible ||
                        editPaymentMethod.id !== paymentMethod.id
                      "
                    >
                      <base-button
                        size="sm"
                        @click="onVerifyPaymentMethod(paymentMethod)"
                        >Verifikasi</base-button
                      >
                      <template v-if="editable">
                        <base-button
                          size="sm"
                          color="white"
                          @click="onEditPaymentMethod(paymentMethod.id)"
                        >
                          <Icon
                            icon="heroicons:pencil-square-solid"
                            class="h-4 w-4"
                          />
                        </base-button>
                        <base-button
                          size="sm"
                          color="white"
                          @click="onDeletePaymentMethod(paymentMethod.id)"
                        >
                          <Icon icon="heroicons:trash-solid" class="h-4 w-4" />
                        </base-button>
                      </template>
                    </template>

                    <base-button
                      v-if="editPaymentMethod.id === paymentMethod.id"
                      size="sm"
                      color="white"
                      @click="onCancelEditPaymentMethod"
                    >
                      <Icon icon="heroicons:x-mark" class="h-4 w-4" />
                      Cancel Edit
                    </base-button>
                  </template>
                </div>
              </td>
              <td></td>
            </tr>
            <tr
              v-if="editable && !payment.attributes.is_verified"
              :key="`${payment.id}-add`"
              :class="[classes.tr, 'bg-gray-50']"
            >
              <td :class="classes.td"></td>
              <td :class="classes.td">
                <button
                  class="flex items-center"
                  @click="onCreatePaymentMethod(payment)"
                >
                  <Icon
                    icon="heroicons:plus-circle"
                    class="mx-auto h-6 w-6 text-green-600 hover:text-green-500"
                  />
                </button>
              </td>
              <td></td>
              <td></td>
              <td colspan="2"></td>
            </tr>
          </template>
        </template>
      </template>
      <template v-if="summary" v-slot:tfoot="{ classes }">
        <tfoot>
          <tr :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td]">Total Penjualan</td>
            <td :class="[classes.td, 'text-right']">
              {{ orderPrice | toCurrency }}
            </td>
          </tr>
          <tr v-if="withTotalPaymentAmount" :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td, 'pt-0']">Total Pembayaran</td>
            <td :class="[classes.td, 'pt-0 text-right']">
              {{ paymentAmount | toCurrency }}
            </td>
          </tr>
          <tr v-if="withTotalPaymentRemainder" :class="classes.tr">
            <td colspan="2"></td>
            <td :class="[classes.td, 'pt-0']">Total Kekurangan</td>
            <td :class="[classes.td, 'pt-0 text-right']">
              {{ Math.max(orderPrice - paymentAmount, 0) | toCurrency }}
            </td>
          </tr>
        </tfoot>
      </template>
    </datatable>
    <create-payment-method-modal
      v-if="editable"
      :order-id="orderId"
      :payment-id="createPaymentMethod.paymentId"
      :visible="createPaymentMethod.visible"
      @close="createPaymentMethod.visible = false"
      @created="loadPaymentMethods"
    />
    <teleport to="body">
      <loading v-if="loading" />
    </teleport>
  </component>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import Teleport from 'vue2-teleport';
import { toCurrency } from '@/services/currency.service';
import { mapActions } from 'vuex';
import CreatePaymentMethodModal from './create-payment-method-modal.vue';

export default {
  mixins: [requestMixin],
  components: { Teleport, CreatePaymentMethodModal },
  props: {
    orderId: String,
    payments: Object,
    orderPrice: Number,
    cardless: Boolean,
    columns: {
      type: Array,
      default: () => ['state'],
    },
    withTotalPaymentAmount: Boolean,
    withTotalPaymentRemainder: Boolean,
    withUnused: Boolean,
    paymentAmount: Number,
    editable: Boolean,
    summary: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['reload', 'verified'],
  data() {
    return {
      collapsedId: null,
      loadingPaymentMethods: false,
      loadingVerifyPayment: false,
      loadingVerifyPaymentMethod: false,
      loadingEditPaymentMethod: false,
      loadingDeletePaymentMethod: false,
      loadingRestorePaymentMethod: false,
      paymentMethods: {
        data: [],
      },
      editPaymentMethod: {
        visible: false,
        id: null,
        value: null,
      },
      createPaymentMethod: {
        visible: false,
        paymentId: null,
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        { id: 'code', name: 'No Pembayaran' },
        { id: 'warehouse_code', name: 'Kode Gudang' },
        { id: 'date', name: 'Tanggal' },
        { id: 'amount', name: 'Total', theadClass: 'text-right' },
        this.columns.includes('state')
          ? { id: 'state', name: '', theadClass: 'text-center' }
          : null,
        { id: 'action', name: '', theadClass: 'text-right' },
      ].filter((i) => i !== null);
    },
    loading() {
      return (
        this.loadingPaymentMethods ||
        this.loadingVerifyPayment ||
        this.loadingVerifyPaymentMethod ||
        this.loadingEditPaymentMethod ||
        this.loadingDeletePaymentMethod ||
        this.loadingRestorePaymentMethod
      );
    },
    usedPaymentMethods() {
      if (!this.withUnused) {
        return this.paymentMethods.data.filter(
          (item) => item.attributes.is_used
        );
      }

      return this.paymentMethods.data;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadPaymentMethods() {
      this.loadingPaymentMethods = true;

      const [res, error] = await this.request(
        `/api/v1/payments/${this.collapsedId}/payment-methods`,
        {
          params: {
            'fields[payment-methods]':
              'updatedAt,created_by_type,payment_method_type,office_bank_name,cashier_payment_amount,payment_amount,is_verified,balance_used,is_used,canceled',
            sort: 'canceled_at',
          },
        }
      );

      if (!error) {
        this.paymentMethods = res;
      }

      this.loadingPaymentMethods = false;
    },
    onCollapse(payment) {
      if (this.collapsedId === payment.id) {
        this.collapsedId = null;
      } else {
        this.collapsedId = payment.id;

        this.editPaymentMethod.id = null;
        this.editPaymentMethod.value = null;
        this.editPaymentMethod.visible = false;

        this.paymentMethods = {
          data: [],
        };

        this.loadPaymentMethods();
      }
    },
    async onVerifyPayment(payment) {
      this.loadingVerifyPayment = true;

      const [, error] = await this.request(
        `/api/v1/payments/${payment.id}/-actions/verify`,
        {
          method: 'post',
        }
      );

      if (!error) {
        this.$emit('verified');
      }

      this.loadingVerifyPayment = false;
    },
    async onVerifyPaymentMethod(paymentMethod) {
      this.loadingVerifyPaymentMethod = true;

      const [, error] = await this.request(
        `/api/v1/payment-methods/${paymentMethod.id}/-actions/verify`,
        {
          method: 'post',
        }
      );

      if (!error) {
        await this.loadPaymentMethods();

        if (
          this.paymentMethods.data.filter(
            (paymentMethod) =>
              !paymentMethod.attributes.canceled &&
              !paymentMethod.attributes.is_verified
          ).length < 1
        ) {
          this.$emit('reload');
        }
      }

      this.loadingVerifyPaymentMethod = false;
    },
    async onEditPaymentMethod(id) {
      const index = this.paymentMethods.data.findIndex(
        (paymentMethod) => paymentMethod.id === id
      );

      this.editPaymentMethod.id = id;
      this.editPaymentMethod.value = toCurrency(
        this.paymentMethods.data[index].attributes.cashier_payment_amount
      );
      this.editPaymentMethod.visible = true;

      await this.$nextTick();

      this.$refs.editPaymentMethodInput[0].$refs.input.focus();
    },
    onCancelEditPaymentMethod() {
      this.editPaymentMethod.id = null;
      this.editPaymentMethod.value = null;
      this.editPaymentMethod.visible = false;
    },
    async onSubmitEditPaymentMethod() {
      this.loadingEditPaymentMethod = true;

      const amount = Number(
        this.editPaymentMethod.value.replace(/\D/gi, '')
      )

      if (amount < 1) {
        this.createAlert({
          status: 'error',
          data: 'Nominal pembayaran minimal 1',
        });
      } else {
        const [res, err] = await this.request(
          `/api/v1/payment-methods/${this.editPaymentMethod.id}`,
          {
            method: 'patch',
            data: {
              data: {
                type: 'payment-methods',
                id: this.editPaymentMethod.id,
                attributes: {
                  cashier_payment_amount: amount,
                },
              },
            },
          }
        );

        if (err) {
          this.createAlert({
            status: 'error',
            data: this.getRequestErrorMessage(err),
          });
        } else {
          const index = this.paymentMethods.data.findIndex(
            (paymentMethod) => paymentMethod.id === this.editPaymentMethod.id
          );

          this.paymentMethods.data[index].attributes.cashier_payment_amount =
            res.data.attributes.cashier_payment_amount;

          this.editPaymentMethod.id = null;
          this.editPaymentMethod.value = null;
          this.editPaymentMethod.visible = false;

          this.$emit('reload');
        }
      }

      this.loadingEditPaymentMethod = false;
    },
    async onDeletePaymentMethod(id) {
      this.loadingDeletePaymentMethod = true;

      const [, err] = await this.request(
        `/api/v1/payment-methods/${id}/-actions/cancel`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.loadPaymentMethods();
        this.$emit('reload');
      }

      this.loadingDeletePaymentMethod = false;
    },
    async onRestorePaymentMethod(id) {
      this.loadingRestorePaymentMethod = true;

      const [, err] = await this.request(
        `/api/v1/payment-methods/${id}/-actions/restore`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.loadPaymentMethods();
        this.$emit('reload');
      }

      this.loadingRestorePaymentMethod = false;
    },
    onCreatePaymentMethod(payment) {
      this.createPaymentMethod.paymentId = payment.id;
      this.createPaymentMethod.visible = true;
    },
  },
};
</script>
