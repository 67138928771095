<template>
  <component
    :is="cardless ? 'div' : 'base-card'"
    title="Tambah Faktur Pembayaran"
  >
    <template #action>
      <base-button color="white" @click="onCreatePaymentMethod"
        >Tambah Metode</base-button
      >
    </template>
    <div class="space-y-4">
      <base-input
        v-if="withCashback && cashback"
        inset
        label="Cashback"
        disabled
        :value="cashback | toCurrency"
      />
      <base-input
        v-if="withStockistBalance && stockistBalance > 0"
        inset
        :label="`Saldo Pembayaran (${toCurrency(stockistBalance)})`"
        disabled
        :value="stockistBalanceUsed | toCurrency"
      />
      <div class="space-y-4">
        <div
          v-for="(payment, index) in paymentMethods"
          :key="index"
          class="relative flex gap-4"
        >
          <base-input inset fullwidth with-label label="Metode Pembayaran">
            <base-select
              inset
              expand
              :options="paymentMethodTypesOptions"
              v-model="payment.paymentMethodTypeId"
              @change="onChangePaymentMethodTypeId(index)"
            />
          </base-input>
          <base-input
            v-if="checkInputBankVisible(index)"
            inset
            fullwidth
            with-label
            label="Pilih Bank"
          >
            <base-select
              inset
              expand
              :options="officeBanksTypeBankOptions"
              v-model="payment.officeBankId"
              @change="onChangeOfficeBankId(index)"
            />
          </base-input>
          <base-input
            v-if="checkInputReceiptVisible(index)"
            type="text"
            placeholder="Nomor Struk"
            fullwidth
            with-label
            label="Nomor Struk"
            inset
            v-model="payment.receiptNumber"
          />
          <base-input
            v-if="checkInputAmountVisible(index)"
            type="text"
            placeholder="Nominal Pembayaran"
            fullwidth
            with-label
            label="Nominal Pembayaran"
            inset
            currency
            v-model="payment.amount"
            @change="onChangeAmount(index)"
          />
          <button
            v-if="paymentMethods.length > 1"
            @click="onRemovePaymentMethod(index)"
            type="button"
            class="focus:outline-none absolute inset-y-0 -top-2 -right-2 flex h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <template v-if="withSummary">
        <hr />
        <div class="grid grid-cols-2 gap-4">
          <base-input
            inset
            disabled
            with-label
            label="Total Pembayaran"
            :value="totalAmount | toCurrency"
          />
          <base-input
            inset
            disabled
            with-label
            :label="totalRemainder > 0 ? 'Kekurangan' : 'Kelebihan'"
            :value="Math.abs(totalRemainder) | toCurrency"
          />
        </div>
      </template>
      <div class="flex justify-end">
        <base-button v-if="withValidateButton && canSave" :disabled="disabledSaveButton" @click="onSave">{{
          validateButtonText
        }}</base-button>
      </div>
    </div>

    <loading v-if="loading" />
  </component>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions, mapGetters } from 'vuex';
import { toCurrency } from '@/services/currency.service';

/*
value: [
  {
    paymentMethodTypeId: null,
    amount: null,
    officeBankId: null,
    receiptNumber: null,
  }
]
*/
export default {
  mixins: [requestMixin],
  props: {
    filterReserved: {
      type: Boolean,
      default: true,
    },
    filterPaymentMethodType: Object,
    autoSave: Boolean,
    withCashback: Boolean,
    withStockistBalance: Boolean,
    orderId: String,
    paymentId: String,
    cashback: {
      type: Number,
      default: 0,
    },
    stockistBalance: {
      type: Number,
      default: 0,
    },
    minAmount: {
      type: Number,
      default: 0,
    },
    paid: {
      type: Number,
      default: 0,
    },
    cardless: Boolean,
    officeId: String,
    value: Array,
    sync: Boolean,
    withValidateButton: {
      type: Boolean,
      default: true,
    },
    withSummary: {
      type: Boolean,
      default: true,
    },
    validateButtonText: {
      type: String,
      default: 'Validasi',
    },
    lessAmountDisabled: Boolean
  },
  emits: ['save', 'input', 'load-payment-methods-finished'],
  data() {
    return {
      loading: false,
      paymentMethodTypes: {
        data: [],
      },
      officeBanks: {
        data: [],
      },
      data: [
        {
          paymentMethodId: null,
          paymentMethodTypeId: null,
          amount: null,
          officeBankId: null,
          receiptNumber: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    paymentMethods: {
      get() {
        return this.sync ? this.value : this.data;
      },
      set(value) {
        if (this.sync) {
          this.$emit('input', value);
        } else {
          this.data = value;
        }
      },
    },
    canSave() {
      return this.paymentMethods.some(
        (paymentMethod) => paymentMethod.paymentMethodTypeId
      );
    },
    disabledSaveButton() {
      if (!this.lessAmountDisabled) {
        return false
      }

      return this.totalPaid < this.minAmount
    },
    hasBank() {
      return this.officeBanks.data.filter(
        (bank) => bank.attributes.office_bank_type === 'bank'
      ).length;
    },
    officeBanksTypeBankOptions() {
      return [
        { key: 'null', value: null, label: 'Pilih Bank' },
        ...this.officeBanks.data
          .filter(
            (officeBank) => officeBank.attributes.office_bank_type === 'bank'
          )
          .map((officeBank) => {
            return {
              key: officeBank.id,
              value: officeBank.id,
              label: `${officeBank.attributes.bank_name} (a.n ${officeBank.attributes.account_name}) (${officeBank.attributes.account_number})`,
            };
          }),
      ];
    },
    paymentMethodTypesOptions() {
      return [
        { key: 'null', value: null, label: 'Pilih Metode Pembayaran' },
        ...this.paymentMethodTypes.data.map((type) => {
          const requireBank = this.checkIsPaymentMethodTypeRequireBank(type);
          const name = type.attributes.name;
          const balance = name === 'Saldo Stockist';
          const disabledOfBank = requireBank && !this.hasBank;
          const disabledOfBalance = balance && this.stockistBalance < 1;
          const disabled = disabledOfBank || disabledOfBalance;

          return {
            key: type.id,
            value: type.id,
            label: disabled
              ? `${name} (${
                  disabledOfBank ? 'Belum ada bank yang ditambahkan' : 0
                })`
              : balance
              ? `${name} (${toCurrency(this.stockistBalance)})`
              : name,
            disabled,
          };
        }),
      ];
    },
    stockistBalanceUsed() {
      return this.withStockistBalance
        ? Math.min(this.minAmount, this.stockistBalance)
        : 0;
    },
    totalAmount() {
      return (
        this.paymentMethods
          .filter((method) => method.paymentMethodTypeId && method.amount)
          .reduce(
            (total, method) =>
              total + Number(method.amount.replace(/\D/gi, '')),
            0
          ) + Math.max(this.stockistBalanceUsed, 0)
      );
    },
    totalPaid() {
      return this.totalAmount + this.paid + this.cashback;
    },
    totalRemainder() {
      return this.minAmount - this.totalPaid;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    toCurrency,
    checkInputAmountVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      if (!paymentMethodTypeId) {
        return false;
      }

      if (
        this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) &&
        !this.paymentMethods[index].officeBankId
      ) {
        return false;
      }

      if (
        this.checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId) &&
        !this.paymentMethods[index].receiptNumber
      ) {
        return false;
      }

      return true;
    },
    checkInputBankVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      return (
        paymentMethodTypeId &&
        this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId)
      );
    },
    checkInputReceiptVisible(index) {
      const paymentMethodTypeId =
        this.paymentMethods[index].paymentMethodTypeId;

      if (!paymentMethodTypeId) {
        return false;
      }

      if (
        !this.checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) ||
        !this.paymentMethods[index].officeBankId
      ) {
        return false;
      }

      if (!this.checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId)) {
        return false;
      }

      return true;
    },
    checkIsPaymentMethodTypeRequireBank(paymentMethodTypeId) {
      const paymentMethodType =
        typeof paymentMethodTypeId === 'object'
          ? paymentMethodTypeId
          : this.paymentMethodTypes.data.find(
              (paymentMethodType) =>
                paymentMethodType.id === paymentMethodTypeId
            );

      return ['Transfer Bank', 'EDC'].includes(
        paymentMethodType.attributes.name
      );
    },
    checkIsPaymentMethodTypeRequireReceipt(paymentMethodTypeId) {
      const paymentMethodType =
        typeof paymentMethodTypeId === 'object'
          ? paymentMethodTypeId
          : this.paymentMethodTypes.data.find(
              (paymentMethodType) =>
                paymentMethodType.id === paymentMethodTypeId
            );

      return ['EDC'].includes(paymentMethodType.attributes.name);
    },
    async loadOfficeBanks() {
      const [res, error] = await this.request(
        `/api/v1/offices/${this.officeId || this.me.office_id}/office-banks`,
        {
          params: {
            'fields[office-banks]':
              'account_name,account_number,bank_name,office_bank_type',
          },
        }
      );

      if (!error) {
        this.officeBanks = res;
      }
    },
    async loadPaymentMethodTypes() {
      const [res, error] = await this.request(
        `/api/v1/offices/${
          this.officeId || this.me.office_id
        }/payment-method-types`,
        {
          params: {
            ...(this.filterReserved ? { 'filter[is_reserved]': false } : {}),
            'fields[payment-method-types]': 'name',
            ...this.filterPaymentMethodType,
          },
        }
      );

      if (!error) {
        this.paymentMethodTypes = res;
      }
    },
    async loadPaymentMethods() {
      const [res, err] = await this.request(
        `/api/v1/payments/${this.paymentId}/payment-methods`,
        {
          params: {
            include: 'payment-method-type,office-bank',
            'fields[payment-methods]':
              'payment_amount,receipt_number,payment-method-type,office-bank,payment_method_type',
            'fields[payment-method-types]': 'name',
            'fields[office-banks]': 'bank_name',
          },
        }
      );

      if (!err) {
        const paymentMethods = res.data.filter(
          (paymentMethod) =>
            paymentMethod.attributes.payment_method_type !== 'Cashback' &&
            paymentMethod.attributes.payment_method_type !== 'Saldo Stockist'
        );

        if (paymentMethods.length) {
          this.paymentMethods = paymentMethods.map((paymentMethod) => {
            const paymentMethodType =
              paymentMethod.relationships['payment-method-type'].data;
            const officeBank = paymentMethod.relationships['office-bank'].data;

            return {
              paymentMethodId: paymentMethod.id,
              paymentMethodTypeId: paymentMethodType
                ? paymentMethodType.id
                : null,
              amount: toCurrency(paymentMethod.attributes.payment_amount),
              officeBankId: officeBank ? officeBank.id : null,
              receiptNumber: paymentMethod.attributes.receipt_number,
            };
          });
        }
      }

      this.$emit('load-payment-methods-finished');
    },
    onChangeOfficeBankId(index) {
      if (!this.checkInputAmountVisible(index)) {
        this.paymentMethods[index].amount = null;
      }

      if (!this.checkInputReceiptVisible(index)) {
        this.paymentMethods[index].receiptNumber = null;
      }
    },
    onChangePaymentMethodTypeId(index) {
      if (!this.paymentMethods[index].paymentMethodTypeId) {
        this.paymentMethods[index].amount = null;
        this.paymentMethods[index].officeBankId = null;
      } else {
        const paymentMethod = this.paymentMethods[index];
        const paymentMethodType = this.paymentMethodTypes.data.find(
          (item) => item.id === paymentMethod.paymentMethodTypeId
        );

        if (paymentMethodType.attributes.name === 'Cash') {
          const officeBank = this.officeBanks.data.find(
            (officeBank) => officeBank.attributes.office_bank_type === 'cash'
          );

          this.paymentMethods[index].officeBankId = officeBank.id;
        }
      }

      if (!this.checkInputReceiptVisible(index)) {
        this.paymentMethods[index].receiptNumber = null;
      }
    },
    onCreatePaymentMethod() {
      this.paymentMethods.push({
        paymentMethodId: null,
        paymentMethodTypeId: null,
        amount: null,
        officeBankId: null,
        receiptNumber: null,
      });
    },
    async onRemovePaymentMethod(index) {
      if (this.autoSave) {
        this.loading = true;

        const paymentMethod = this.paymentMethods[index];

        await this.request(
          `/api/v1/payment-methods/${paymentMethod.paymentMethodId}`,
          {
            method: 'delete',
          }
        );

        this.loading = false;
      }

      this.paymentMethods.splice(index, 1);
    },
    onSave() {
      this.$emit('save', this.paymentMethods);
    },
    async onChangeAmount(index) {
      if (this.autoSave) {
        this.loading = true;

        const paymentMethod = this.paymentMethods[index];
        const amount = typeof paymentMethod.amount === 'string'
          ? Number(paymentMethod.amount.replace(/\D/g, ''))
          : 0

        if (amount < 1) {
          this.createAlert({
            status: 'error',
            data: 'Nominal pembayaran minimal 1',
          });
        } else {
          if (paymentMethod.paymentMethodId) {
            const [, err] = await this.request(
              `/api/v1/payment-methods/${paymentMethod.paymentMethodId}`,
              {
                method: 'patch',
                data: {
                  data: {
                    type: 'payment-methods',
                    id: paymentMethod.paymentMethodId,
                    attributes: {
                      payment_amount: amount,
                      receipt_number: paymentMethod.receiptNumber,
                    },
                    relationships: {
                      ...(paymentMethod.officeBankId
                        ? {
                            'office-bank': {
                              data: {
                                type: 'office-banks',
                                id: paymentMethod.officeBankId,
                              },
                            },
                          }
                        : {}),
                      ...(paymentMethod.paymentMethodTypeId
                        ? {
                            'payment-method-type': {
                              data: {
                                type: 'payment-method-types',
                                id: paymentMethod.paymentMethodTypeId,
                              },
                            },
                          }
                        : {
                            'payment-method-type': {
                              data: null,
                            },
                          }),
                    },
                  },
                },
              }
            );

            if (err) {
              this.createAlert({
                status: 'error',
                data: this.getRequestErrorMessage(err),
              });
            }
          } else {
            const [res, err] = await this.request(`/api/v1/payment-methods`, {
              method: 'post',
              data: {
                data: {
                  type: 'payment-methods',
                  attributes: {
                    payment_amount: amount,
                    receipt_number: paymentMethod.receiptNumber,
                  },
                  relationships: {
                    order: {
                      data: {
                        type: 'orders',
                        id: this.orderId,
                      },
                    },
                    payment: {
                      data: {
                        type: 'payments',
                        id: this.paymentId,
                      },
                    },
                    ...(paymentMethod.officeBankId
                      ? {
                          'office-bank': {
                            data: {
                              type: 'office-banks',
                              id: paymentMethod.officeBankId,
                            },
                          },
                        }
                      : {}),
                    ...(paymentMethod.paymentMethodTypeId
                      ? {
                          'payment-method-type': {
                            data: {
                              type: 'payment-method-types',
                              id: paymentMethod.paymentMethodTypeId,
                            },
                          },
                        }
                      : {}),
                  },
                },
              },
            });

            if (err) {
              this.createAlert({
                status: 'error',
                data: this.getRequestErrorMessage(err),
              });
            } else {
              this.paymentMethods[index].paymentMethodId = res.data.id;
            }
          }
        }

        this.loading = false;
      }
    },
  },
  created() {
    this.loadOfficeBanks();
    this.loadPaymentMethodTypes();

    if (this.autoSave && !this.sync) {
      this.loadPaymentMethods();
    }
  },
};
</script>
